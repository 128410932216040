import * as store from 'store';

export enum LocalStorageKeys {
    username = 'username',
    userid = 'userid'
}

type Key = LocalStorageKeys | string;

const createLSGetter =
    <T = string>(key: Key) =>
    (): T | undefined =>
        store.get(key);

const createLSSetter =
    <T>(key: Key) =>
    (newValue?: T) => {
        store.set(key, newValue);
    };

const createLSDeleter = (key: Key) => () => {
    store.remove(key);
};

export class LocalStorage {
    // getters
    public static getUsername = createLSGetter<string>(LocalStorageKeys.username);
    public static getUserId = createLSGetter<string>(LocalStorageKeys.userid);
    // setters
    public static setUsername = createLSSetter<string>(LocalStorageKeys.username);
    public static setUserId = createLSSetter<string>(LocalStorageKeys.userid);
    // resetters
    public static resetUsername = createLSDeleter(LocalStorageKeys.username);
    public static resetUserId = createLSDeleter(LocalStorageKeys.userid);
}
