import React, { useEffect, useRef } from 'react';
import KalmanFilter from "kalmanjs";
import { SensorData } from '../logic/models';
import {DeviceDetector} from "../utils/browserDetection";
import {accFilter} from "../utils/filter";

interface SpectrumVisualizerProps {
    motionData: SensorData;
}

const hideNoise = false;
const filterNoise = false;
const kf = new KalmanFilter();

// A helper function to interpolate between two values
const interpolateValues = (
    start: number | null | undefined = 0,
    end: number | null | undefined = 0,
    steps: number
): number[] => {
    const values = [];
    const stepSize = (end! - start!) / (steps + 1);

    for (let i = 1; i <= steps; i++) {
        values.push(start! + i * stepSize);
    }

    return values;
};

export const SpectrumVisualizer: React.FC<SpectrumVisualizerProps> = ({ motionData }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const use = (n?: number | null) => (n === null || n === undefined ? 0 : n);
    let graphX = useRef<number>(0);


    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        function drawGraph(
            gh: number,
            gh2: number,
            val: number | null = 0,
            pos: number,
            color: string
        ) {
            const size = Math.max(-gh2, Math.min(val! * 20, gh2));
            if (ctx) {
                ctx.fillStyle = color;
                ctx.fillRect(graphX.current, gh * pos + gh2, 1, size);
            }
        }

        if (ctx && canvasRef.current) {
            ctx.fillStyle = "white";
            ctx.fillRect(graphX.current, 0, 2, canvasRef.current.height);
            ctx.clearRect(graphX.current, 0, 1, canvasRef.current.height);
            const scale=5;
            const gh = canvasRef.current.height / 4; // vorher 4
            const gh2 = gh * 2; // vorher 2
            const pos = 0;
            const posRange = 0;
            //convertMotionToUint8Array(e);
            const { alpha, beta, gamma, x,y, z } = motionData;
            const r = Math.sqrt(
                alpha! * alpha! + beta! * beta! + gamma! * gamma!
            );

            const filteredR = accFilter.filter(alpha!, beta!, gamma!);
            //console.log("FILTERD", filteredR);

            if (!hideNoise) {
                drawGraph(
                    gh,
                    gh2,
                    filterNoise ? filteredR * scale : r * 2,
                    pos,
                    "yellow"
                );
                drawGraph(
                    gh,
                    gh2,
                    filterNoise ? -filteredR * scale : -r * 2,
                    pos,
                    "yellow"
                );
            }

            if (!filterNoise) {
                drawGraph(gh, gh2, z! * scale, pos - posRange, "aqua");
                drawGraph(gh, gh2, y! * scale, pos - posRange, "purple");
                drawGraph(gh, gh2, x! * scale, pos - posRange, "lime");
            } else {
                drawGraph(
                    gh,
                    gh2,
                    kf.filter(z!) * scale,
                    pos - posRange,
                    "aqua"
                );
                drawGraph(
                    gh,
                    gh2,
                    kf.filter(y!) * scale,
                    pos - posRange,
                    "purple"
                );
                drawGraph(
                    gh,
                    gh2,
                    kf.filter(x!) * scale,
                    pos - posRange,
                    "lime"
                );
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
            graphX.current = (graphX.current + 1) % canvasRef.current.width;
        }
    }, [motionData, graphX, canvasRef]);

    return (
        <div>
            <canvas ref={canvasRef} style={{ width: '100%' }} height={400} />
        </div>
    );
};
