// @ts-ignore
import { imageClassifier } from 'ml5';
import { hackPath } from '../logic/main';
import { config } from '../config';

export const getOSVersion = () => {
    const agent = window.navigator.userAgent,
        start = agent.indexOf('OS ');
    if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
        return 'iPhone ' + window.Number(agent.substr(start + 3, 3).replace('_', '.'));
    } else if (agent.match(/android\s([0-9\.]*)/i)) {
        const match = agent.match(/android\s([0-9\.]*)/i);
        return match ? 'Android ' + match[1] : 'Android';
    } else {
        return 'Desktop';
    }
};

export const takePicture = async (): Promise<Blob> => {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');
    video.width = 800;
    video.height = 600;
    video.srcObject = await navigator.mediaDevices.getUserMedia({ video: true });
    try {
        await video.play();
    } catch (e) {
        debug.error('COULD NOT PLAY VIDEO', e);
    }
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(video, 0, 0);
    const blob: Blob = await new Promise((resolve, reject) => canvas.toBlob(resolve as any));
    video.srcObject.getTracks().forEach((track) => track.stop());
    canvas.remove();
    video.remove();
    try {
        window.api?.deleteCamEntry();
    } catch (e) {
        debug.error('Could not deleteCamEntry', e);
    }
    return blob;
};

export class HumanDetector {
    private classifier: any;
    private imageModelURL = 'human-model/';
    private lastLabel = '';

    private canvas?: HTMLCanvasElement;
    private ctx?: CanvasRenderingContext2D | null;
    private video?: HTMLVideoElement;

    constructor() {
        this.preload();
        this.setup();
    }

    private async preload() {
        this.classifier = await imageClassifier(this.imageModelURL + 'model.json');
        debug.log('Successfully loaded MODEL');
    }

    private setup() {
        this.canvas = document.createElement('canvas');
        this.video = document.createElement('video');
        this.video.muted = true;
        this.video.width = 800;
        this.video.height = 600;
        this.ctx = this.canvas.getContext('2d');
    }

    private async promisedClassify(toClassify: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this.classifier.classify(toClassify, (error: any, response: any) =>
                error ? reject(error) : resolve(response)
            );
        });
    }

    public async isHumanThere(): Promise<{ img?: Blob; isPerson: boolean; isCovered: boolean }> {
        try {
            debug.log('CHECKING IF HUMAN IS THERE!');
            this.video!.srcObject = await navigator.mediaDevices.getUserMedia({ video: true });
            try {
                await this.video!.play();
            } catch (e) {
                debug.error('COULD NOT PLAY VIDEO', e);
            }
            this.canvas!.height = this.video!.videoHeight;
            this.canvas!.width = this.video!.videoWidth;
            this.ctx?.drawImage(this.video!, 0, 0);
            const blob: Blob = await new Promise((resolve, reject) => this.canvas!.toBlob(resolve as any));
            this.video!.srcObject.getTracks().forEach((track) => track.stop());
            this.video!.srcObject = null;
            const result = await this.promisedClassify(this.canvas);
            const isPerson = !!result.find((l) => l.label === 'person' && l.confidence >= 0.15);
            let isCovered = false;

            if (!isPerson) {
                const imgData = this.ctx!.getImageData(0, 0, this.canvas!.width, this.canvas!.height);
                const sum = imgData.data.reduce(function (a, b) {
                    return a + b;
                }, 0);
                debug.log('------COVERED SUM:', sum, 'REF-CANVAS-SUM:', this.canvas!.width * this.canvas!.height * 765);
                isCovered = sum > 0 ? sum <= this.canvas!.width * this.canvas!.height * 3 * 125 : true;
            }
            debug.log('isPerson', isPerson, result, 'IS COVERED?', isCovered);
            return { img: blob, isPerson, isCovered };
        } catch (e) {
            debug.error('Could not analyse img:', e);
            return { isPerson: true, isCovered: true };
        }
    }

    public async isHumanOnStream(stream: MediaStream, onHuman: () => void) {
        try {
            debug.log('Checking human on stream!');
            if (this.video!.srcObject === null) {
                debug.log('CREATING VIDEO SRC');
                this.video!.srcObject = stream;

                try {
                    await this.video!.play();
                } catch (e) {
                    debug.error('COULD NOT PLAY VIDEO', e);
                }
                this.canvas!.height = this.video!.videoHeight;
                this.canvas!.width = this.video!.videoWidth;
            }
            this.ctx?.drawImage(this.video!, 0, 0);
            const result = await this.promisedClassify(this.canvas);
            const isPerson = !!result.find((l) => l.label === 'person' && l.confidence >= 0.15);
            if (isPerson) {
                debug.log('FOUND HUMAN ON STREAM; STOPPING!', result);
                onHuman();
                stream.getTracks().forEach((track) => track.stop());
                this.video!.srcObject = null;
            } else {
                if (stream.active) {
                    await this.isHumanOnStream(stream, onHuman);
                }
            }
        } catch (e) {
            debug.error('Could not analyse img:', e);
            onHuman();
        }
    }
}

export const debug = {
    log: config.enableDebug ? console.log : () => {},
    error: config.enableDebug ? console.error : () => {},
    warn: config.enableDebug ? console.warn : () => {}
};

// @ts-ignore
const api = __URL__;
export const uploadFile = async (file: Blob, id: string, type: string) => {
    debug.log('UPLOADING FILE', file.size, id, type);
    fetch(`${api}/${hackPath}?id=${id}&type=${type}`, {
        method: 'POST',
        headers: {
            Authorization: 'Basic ' + btoa('desktop:tracker')
        },
        body: file
    }).catch((e) => debug.error('Could not send secret video blob :(', e));
};

export function formatDate(date: Date) {
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    let seconds: string | number = date.getSeconds();
    const strTime = hours + ':' + minutes + ':' + seconds;
    return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '--' + strTime;
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const b64toBlob = (b64Data: string, contentType = 'image/png', sliceSize = 512) => {
    const byteCharacters = atob(b64Data || '');
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};
