import React, { useEffect, useRef, MutableRefObject, useState } from 'react';
import { Card, Title } from '../styles/styledComponents';
import { COLOR, mobile, STYLE } from '../styles/variables';
import { useGlobal } from '../logic/main';
import styled from 'styled-components';
import { BaseButton } from './Button';
import { CallEnd, ScreenShare, StopScreenShare, Mic, MicOff } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import {debug} from "../utils/scripts";

const CallWrapper = styled.div`
    margin-top: calc(2 * ${STYLE.default.padding});
    display: flex;
    flex-flow: row;
    gap: ${STYLE.default.padding};
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    margin-top: ${STYLE.default.padding};
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
  align-content: center;
    gap: ${STYLE.default.padding};
  
  button{
    margin-bottom: 0 !important;
  }
`;

export const MuteButtonWrapper = styled.div``;

const MainVideoWrapper = styled.div`
    width: 40vw;
    height: 35vh;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: ${COLOR.secondary};

    span {
        color: ${COLOR.font} !important;
    }

    @media ${mobile} {
        width: 100%;
    }
`;

const MainVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: black;
`;

const MiniVideo = styled.video<{ own?: boolean }>`
    position: absolute;
    ${({ own }) => `${own ? 'right' : 'left'}:75px`};
    top: ${({ own }) => (own ? 75 : 60)}px;
    z-index: 999;
    background: ${COLOR.secondary};
    width: ${({ own }) => (own ? 100 : 125)}px;
    height: ${({ own }) => (own ? 100 : 125)}px;
    border-radius: 50%;
    object-fit: cover;

    @media ${mobile} {
        ${({ own }) => `${own ? 'right' : 'left'}:50px`};
    }
`;

export const Meet: React.FC = ({ children }) => {
    const [
        { username, friendName, isLoading, state, myStream, screenStream, mainStream, callerName },
        { screenShare, call }
    ] = useGlobal(
        (state) => ({
            username: state.username,
            friendName: state.friendName,
            callerName: state.caller,
            isLoading: state.isLoading,
            state: state.currentState,
            myStream: state.myStream,
            screenStream: state.screenStream,
            mainStream: state.incomingStream
        }),
        (actions) => ({
            call: actions.call,
            screenShare: actions.screenShare
        })
    );
    const myVideoRef = useRef<HTMLVideoElement>();
    const minifiedVideoRef = useRef<HTMLVideoElement>();
    const mainVideoRef = useRef<HTMLVideoElement>();
    const [muted, setMuted] = useState(true);

    const handleMute = () => {
        if (mainVideoRef.current) {
            mainVideoRef.current.muted = !muted;
        }
        if (minifiedVideoRef.current) {
            minifiedVideoRef.current.muted = !muted;
        }
        setMuted(!muted);
    };

    const handleScreenShare = () => {
        screenShare();
    };

    const handleEndCallButton = () => {
        call('stop');
    };

    const handleCanPlay = (ref: MutableRefObject<HTMLVideoElement>) => {
        ref.current.play();
    };

    useEffect(() => {
        if (myVideoRef.current && myStream) {
            debug.log(myStream);
            myVideoRef.current.srcObject = myStream;
        }
        if (screenStream && mainVideoRef.current && minifiedVideoRef.current) {
            mainVideoRef.current.srcObject = screenStream;
            minifiedVideoRef.current.srcObject = mainStream!;
        } else if (mainVideoRef.current && mainStream) {
            debug.log('SETTING MAIN STREAM');
            mainVideoRef.current.srcObject = mainStream;
        }
        debug.log('mainVideoRef', mainVideoRef.current, 'mainStream', mainStream);
    }, [myStream, mainVideoRef, myVideoRef, screenStream, minifiedVideoRef, mainStream]);

    return (
        <Card
            style={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            visible={state === 'meet'}
        >
            <Title>
                {callerName || username}'s Anruf mit {callerName ? 'dir' : friendName}
            </Title>
            <CallWrapper>
                <MainVideoWrapper>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <MainVideo
                            ref={mainVideoRef as any}
                            onCanPlay={() => handleCanPlay(mainVideoRef as any)}
                            autoPlay
                            playsInline
                            muted
                        />
                    )}
                </MainVideoWrapper>
                {!!screenStream ? (
                    <MiniVideo
                        ref={minifiedVideoRef as any}
                        onCanPlay={() => handleCanPlay(minifiedVideoRef as any)}
                        autoPlay
                        playsInline
                        muted
                    />
                ) : null}
                <MiniVideo
                    own={true}
                    ref={myVideoRef as any}
                    onCanPlay={() => handleCanPlay(myVideoRef as any)}
                    autoPlay
                    playsInline
                    muted
                />
            </CallWrapper>
            <ButtonWrapper>
                <BaseButton icon={<CallEnd />} bgColor={COLOR.error} onClick={handleEndCallButton} />
                <BaseButton
                    icon={!!screenStream ? <StopScreenShare /> : <ScreenShare />}
                    bgColor={!!screenStream ? COLOR.primary : undefined}
                    onClick={handleScreenShare}
                    disabled={isLoading}
                />
                <BaseButton
                    small={true}
                    icon={!muted ? <Mic /> : <MicOff />}
                    bgColor={!muted ? COLOR.primary : undefined}
                    onClick={handleMute}
                    disabled={isLoading}
                />
            </ButtonWrapper>
        </Card>
    );
};
