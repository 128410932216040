import React, { useEffect, useRef, MutableRefObject } from 'react';
import { Card, Title } from '../styles/styledComponents';
import { COLOR, mobile, STYLE } from '../styles/variables';
import { useGlobal } from '../logic/main';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { debug } from '../utils/scripts';
import { SpectrumVisualizer } from './MotionViewer';

const CallWrapper = styled.div`
    margin-top: calc(2 * ${STYLE.default.padding});
    display: flex;
    flex-flow: row;
    gap: ${STYLE.default.padding};
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const MainVideoWrapper = styled.div`
    width: 40vw;
    height: 40vh;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: ${COLOR.secondary};

    span {
        color: ${COLOR.font} !important;
    }

    @media ${mobile} {
        width: 100%;
    }
`;

const MainVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: black;
`;

export const RootMeet: React.FC = () => {
    const [{ callType, friendName, isLoading, mainStream, callerName, eventData }] = useGlobal((state) => ({
        callType: state.callType,
        friendName: state.friendName,
        callerName: state.caller,
        isLoading: state.isLoading,
        mainStream: state.incomingStream,
        eventData: state.eventData
    }));
    const mainVideoRef = useRef<HTMLVideoElement>();

    const handleCanPlay = (ref: MutableRefObject<HTMLVideoElement>) => {
        ref.current.play();
    };

    useEffect(() => {
        if (mainVideoRef.current && mainStream) {
            debug.log('SETTING MAIN STREAM');
            mainVideoRef.current.srcObject = mainStream;
        }
        debug.log('mainVideoRef', mainVideoRef.current, 'mainStream', mainStream);
    }, [mainVideoRef, mainStream]);

    return (
        <Card
            style={{
                top: STYLE.default.smallMargin,
                right: STYLE.default.smallMargin,
                alignItems: 'center',
                justifyContent: 'center'
            }}
            visible={true}
        >
            <Title>
                {callerName || friendName ? (callerName || friendName) + "'s " : 'Keine aktive Verbindung'}
                {callType || ''}
            </Title>
            <CallWrapper>
                <MainVideoWrapper>
                    {isLoading ? (
                        <CircularProgress />
                    ) : mainStream ? (
                        <MainVideo
                            ref={mainVideoRef as any}
                            onCanPlay={() => handleCanPlay(mainVideoRef as any)}
                            autoPlay
                            playsInline
                            controls
                            muted
                        />
                    ) : eventData ? (
                        <SpectrumVisualizer motionData={eventData} />
                    ) : null}
                </MainVideoWrapper>
            </CallWrapper>
        </Card>
    );
};
