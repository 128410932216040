import { createGlobalStyle } from 'styled-components';
import { COLOR, mobile, big } from './variables';

export const GlobalStyle = createGlobalStyle`
  html {
    margin: 0;
  }

  body {
    margin: 0;
    width: 100%;
    overflow-x: hidden;
    font-family: 'Open Sans', 'OpenSans', Helvetica, sans-serif;
    font-size: 15px;
    line-height: 1.75;
    background-color: ${COLOR.pageBackground};

    @media ${big} {
      font-size: 16px;
    }

    @media ${mobile} {
      font-size: 14px;
    }
  }

  main {
    margin: 0 auto;
    display: block;
    background: ${COLOR.contentBackground};
  }

  a {
    color: white;
  }

  b {
    font-weight: 700;
  }

  p {
    hyphens: auto;
  }

  * {
    box-sizing: border-box;
  }

  .line {
    opacity: 0.5;
    border-color: white !important;
  }

  .ReactModalPortal {
    display: initial;

    .ReactModal__Overlay {
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      box-sizing: border-box;
    }
  }

  .root {
    overflow-x: hidden;
  }

  .MuiPopover-root {
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.25);

    .MuiPopover-paper {
      padding: 0.75rem;
      margin-top: -1rem;
    }

  }
`;
