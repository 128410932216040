import React, { ChangeEvent, useState } from 'react';
import { Card, Title } from '../styles/styledComponents';
import { COLOR, STYLE } from '../styles/variables';
import { Input } from './Input';
import {permitMotion, useGlobal} from '../logic/main';
import styled from 'styled-components';
import { BaseButton } from './Button';
import { Call } from '@mui/icons-material';

const CallWrapper = styled.div`
    margin-top: calc(2 * ${STYLE.default.padding});
    display: flex;
    flex-flow: row;
    gap: ${STYLE.default.padding};
`;

const FriendWrapper = styled.div`
    display: flex;
    flex-flow: column;
    max-width: 200px;
    gap: calc(${STYLE.default.padding} / 2);

    span {
        font-size: 0.75rem;
    }
`;

export const Home: React.FC = ({ children }) => {
    const [{ username, friendName, state, socketManager }, { setUsername, setFriendName, call, setSocketManager }] =
        useGlobal(
            (state) => ({
                username: state.username,
                friendName: state.friendName,
                state: state.currentState,
                socketManager: state.socketManager
            }),
            (actions) => ({
                setUsername: actions.setUserName,
                setFriendName: actions.setFriendName,
                call: actions.call,
                setSocketManager: actions.setSocketManager
            })
        );

    const [userErrorText, setUserErrorText] = useState<string | undefined>();
    const [friendErrorText, setFriendErrorText] = useState<string | undefined>();

    const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setUsername(ev.target.value);
        setUserErrorText(undefined);
    };

    const handleFriendNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setFriendName(ev.target.value);
        setFriendErrorText(undefined);
    };

    const handleCallButton = () => {
        if (username && friendName) {
            permitMotion();
            call('start');
        } else {
            if (!username) {
                setUserErrorText('Bitte sag uns, wie du heißt!');
            }
            if (!friendName) {
                setFriendErrorText('Wen willst du anrufen?');
            }
        }
    };

    return (
        <Card style={{ top: STYLE.default.margin, right: STYLE.default.margin }} visible={state === 'home'}>
            <Title>
                <span style={{ marginRight: '5px' }}>Hey,</span>{' '}
                <Input
                    placeholder={username ? undefined : 'Dein Name'}
                    value={username}
                    onBlur={setSocketManager}
                    onInputChange={handleNameChange}
                    errorText={userErrorText}
                />
            </Title>
            <CallWrapper>
                <BaseButton
                    icon={<Call />}
                    bgColor={username && friendName ? COLOR.info : undefined}
                    onClick={handleCallButton}
                />
                <FriendWrapper>
                    <Input
                        placeholder={'Name deines Freundes'}
                        value={friendName}
                        onInputChange={handleFriendNameChange}
                        disabled={!socketManager}
                        errorText={friendErrorText}
                        width={12}
                        labelText={'Gib bitte den Namen deines Freundes ein, den du anrufen möchtest'}
                    />
                </FriendWrapper>
            </CallWrapper>
        </Card>
    );
};
