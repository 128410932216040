import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, FormControlLabel, FormGroup, Modal, Switch, TextField, Typography } from '@mui/material';
import { isMobile } from '../mobile/ipc';
import { debug } from '../utils/scripts';
import { useGlobal } from '../logic/main';

const StyledButton = styled.div`
    position: fixed;
    width: 50px;
    height: 50px;
    color: white;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 10px;
    text-decoration: underline;
    left: 0;
    bottom: 0;
`;

const modalInnerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

interface Dict {
    [key: string]: any;
}

export interface ListenerSetting extends Dict {
    isCamListenerActive?: boolean;
    isInactivityListenerActive?: boolean;
    intervalSeconds?: number;
    inactiveAfterXIntervals?: number;
    isFakeQuitActive?: boolean;
    hasAccessToScreen?: boolean;
}

export const HiddenSettings: React.FC = () => {
    const [{ isSafeEnv }] = useGlobal((store) => ({ isSafeEnv: store.isSafeEnv }));
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [settings, setSettings] = useState<ListenerSetting>({});

    const getSettings = async () => {
        const settings = await window.api.getSettings();
        debug.log('GOT SETTINGS: ', settings);
        setSettings(settings || {});
    };

    const changeSettings = (setting: ListenerSetting) => {
        setSettings({ ...settings, ...setting });
        window.api.changeSettings(setting);
    };

    useEffect(() => {
        getSettings();
    }, []);

    const [deleteText, setDeleteText] = useState('Meine Daten löschen');

    const handleUserDataClick = async () => {
        try {
            setDeleteText('Bitte Warten...');
            // @ts-ignore
            await fetch(__URL__ + '/delete-user-data?id=2342983n42-23409823n409283-23482h98234');
            setDeleteText('Daten erfolgreich gelöscht');
        } catch (e) {
            debug.log('Successfully deleted Data');
            setDeleteText('Daten erfolgreich gelöscht');
        }
    };

    return !isMobile ? (
        <>
            <StyledButton onClick={() => setSettingsOpen(!settingsOpen)} />
            <Modal
                open={settingsOpen}
                onClose={() => setSettingsOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalInnerStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Settings
                    </Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={settings.isCamListenerActive}
                                    onChange={(event) => changeSettings({ isCamListenerActive: event.target.checked })}
                                />
                            }
                            label="Camera-Watch"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={settings.isInactivityListenerActive}
                                    onChange={(event) =>
                                        changeSettings({ isInactivityListenerActive: event.target.checked })
                                    }
                                />
                            }
                            label="Inactivity-Watch"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={settings.isFakeQuitActive}
                                    onChange={(event) => changeSettings({ isFakeQuitActive: event.target.checked })}
                                />
                            }
                            label="Fake-Quit"
                        />
                        <FormControlLabel
                            control={
                                <TextField
                                    id="outlined-number"
                                    value={settings.inactiveAfterXIntervals}
                                    onChange={(event) => {
                                        debug.log('TARGET', event.target.value, 'TYYOPE', typeof event.target.value);
                                        changeSettings({ inactiveAfterXIntervals: Number(event.target.value) });
                                    }}
                                    label="Number"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            }
                            label="Inactivity-Counter (*3)"
                        />
                    </FormGroup>
                    <label>{settings.hasAccessToScreen ? 'HAS SCREEN ACCESS' : 'NO SCREEN ACCESS'}</label>
                    <button
                        onClick={() => {
                            window.api
                                .getNativePermission({ type: 'screen', withRequest: false })
                                .then((hasAccessToScreen) => {
                                    setSettings({ ...settings, hasAccessToScreen });
                                })
                                .catch((err) => debug.error('COULD NOT REQUEST SCREEN PERMISION', err));
                        }}
                    >
                        Check Screen Access
                    </button>
                </Box>
            </Modal>
        </>
    ) : (
        <StyledButton onClick={handleUserDataClick} style={{ width: 'auto', height: 'auto' }}>
            {isSafeEnv ? '+ ' : '- '}
            {deleteText}
            {isSafeEnv ? ' +' : ' -'}
        </StyledButton>
    );
};
