export type CSSUnit = 'rem' | 'em' | 'px' | 'vw' | 'vh' | '%';

/**
 * Get the inverted color
 * @param hex - HEX color code
 */
export const invertHex = (hex: string) => '#' + (Number(`0x1${hex}`) ^ 0xffffff).toString(16).substr(3);

/**
 * Get transparency within hex color string
 * @param hex - HEX color code
 * @param opacity
 */
export function hexOpacity(hex: string, opacity = 0.5) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        // @ts-ignore
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
    }
    throw new Error('Bad Hex');
}

/**
 * Splits CSS value (e.g. 12px) into value and unit (e.g. {value: 12, unit: "px"})
 * @param value - CSS value (e.g. 12px)
 */
export const toValueUnit = (value: string) => ({
    value: parseFloat(value.replace(/rem|em|px|vh|vw|%/g, '')),
    unit: value.replace(/[0-9]/g, '') as CSSUnit
});
export const getValue = (value: string) => toValueUnit(value).value;
