import React from 'react';
import styled, { css } from 'styled-components';
import { ButtonBase } from '@mui/material';
import { COLOR, STYLE, wiggle } from '../styles/variables';

const StyledWrapper = styled(ButtonBase)<{ animated?: boolean; small?: boolean }>`
    display: flex;
    flex-flow: column;
    align-items: center;
    box-shadow: ${STYLE.default.shadow} !important;
    margin-bottom: ${({ small }) => (small ? 0 : STYLE.default.padding)} !important;
    border-radius: 50% !important;
    padding: ${({ small }) => (small ? `calc(${STYLE.default.padding} / 2)` : STYLE.default.padding)} !important;
    transition: transform 0.25s ease-in-out;
    animation: ${({ animated }) => (animated ? css`2s ${wiggle} infinite ease-in-out` : 'none')};

    :hover {
        transform: scale(1.1);
    }
`;

interface Props {
    icon?: React.ReactElement;
    color?: string;
    bgColor?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
    disabled?: boolean;
    animation?: boolean;
    small?: boolean;
}

export const BaseButton: React.FC<Props> = ({ icon, small, color, bgColor, style, onClick, disabled, animation }) => (
    <StyledWrapper
        disabled={disabled}
        small={small}
        style={{
            color: color || 'white',
            backgroundColor: bgColor || COLOR.secondary,
            opacity: disabled ? 0.25 : 1,
            ...style
        }}
        onClick={onClick}
        animated={animation}
    >
        {icon}
    </StyledWrapper>
);
