import { io, Socket } from 'socket.io-client';
import { SignalData } from 'simple-peer';
import { StreamType, User } from './main';
import { debug } from '../utils/scripts';
import { SensorData } from './models';

export class SocketManager {
    private socket: Socket;
    private readonly deviceId: string;
    private readonly osInfo: string;
    private readonly name: string;
    private currentPeer?: typeof window.SimplePeer.prototype;
    private caller?: string;
    private callee?: string;
    private callerName?: string;
    private callerSignal?: SignalData;

    // @ts-ignore
    private URI = __URL__;

    public socketId?: string;

    constructor(
        deviceId: string,
        osInfo: string,
        name: string,
        onCall: (name: string, type?: StreamType, jusAudio?: boolean) => void,
        onLeftCall: () => void,
        onNotAvailable: (root?: boolean) => void,
        onReceive?: (data: { webcam?: Blob; event: SensorData }) => void,
        onUsersOnlineChange?: (users: User[]) => void
    ) {
        this.name = name;
        this.deviceId = deviceId;
        this.osInfo = osInfo;
        this.socket = io(this.URI, {
            query: {
                token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZXN0IjoidGVzdCJ9.FsH18D7yHfCMHQCCXaAGMfcUG-TA8kwBQNWogc3ZEvY'
            }
        });
        this.socket.on('me', (id) => {
            this.socketId = id;
            this.setUserInfos();
        });

        this.socket.on('userleftcall', onLeftCall);

        this.socket.on('receivedata', onReceive ? onReceive : () => {});

        this.socket.on('calluser', ({ from, name, signal, type, justAudio }) => {
            debug.log('RECEIVED CALL USER FROM', from, name);
            this.callerName = name;
            this.caller = from;
            this.callerSignal = signal;
            onCall(name, type, justAudio);
        });

        this.socket.on('usersonline', onUsersOnlineChange ? onUsersOnlineChange : () => {});

        this.socket.on('usernotavailable', ({ root }) => {
            debug.log('User is not available!!! is root?', root);
            onNotAvailable(root);
            if (!root) {
                debug.log('LEAVING CALL BECAUS USER NOT AVAILABLE');
                this.leaveCall();
            }
        });
    }

    public updateMyStream = (stream: MediaStream) => {
        if (this.currentPeer) {
            this.currentPeer.replaceTrack(
                (this.currentPeer as any).streams[0].getVideoTracks()[0],
                stream.getVideoTracks()[0],
                (this.currentPeer as any).streams[0]
            );
        }
    };

    public callUser = (
        userToCall: string,
        stream?: MediaStream,
        cb?: (userStream: MediaStream) => void,
        type: StreamType = 'video',
        justAudio?: boolean
    ) => {
        debug.log('SOCKET - STARTIN CALL USER', userToCall, type);

        if (type !== 'motion') {
            const otherOptions =
                this.name === 'root' ? { offerOptions: { offerToReceiveAudio: true, offerToReceiveVideo: true } } : {};

            this.currentPeer = new window.SimplePeer({
                initiator: true,
                trickle: false,
                stream,
                ...otherOptions
            });

            this.currentPeer.on('signal', (data) => {
                this.socket.emit('calluser', {
                    userToCall,
                    signalData: data,
                    from: this.socketId,
                    name: this.name,
                    justAudio,
                    type
                });
            });

            this.currentPeer.on('stream', (stream) => {
                debug.log('GOT PEER STREAM');
                if (cb) {
                    cb(stream);
                }
            });
        } else {
            this.socket.emit('calluser', {
                userToCall,
                from: this.socketId,
                name: this.name,
                type
            });
        }

        this.callee = userToCall;
        this.socket.on('callaccepted', this.callAcceptedHandler); // JUST ONCE?
    };

    private callAcceptedHandler = ({ signal, to }: any) => {
        debug.log('SOCKET - GOT CALL ACCEPTED FROM:', to, 'and currentpeer is', this.currentPeer);
        this.callee = to;
        this.currentPeer?.signal(signal);
    };

    public sendData = (data: {
        mousePos?: { x: number; y: number };
        webcam?: Blob;
        isPerson?: boolean;
        event?: SensorData;
    }) => {
        console.log('EMITTING DATA!', { ...data, id: this.deviceId, name: this.name });
        this.socket.emit('senddata', { ...data, id: this.deviceId, name: this.name });
    };

    public answerCall = (stream?: MediaStream, cb?: (userStream: MediaStream) => void) => {
        debug.log('Trying to answer Call to', this.caller, this.callerName);
        const otherOptions =
            this.callerName === 'root'
                ? { answerOptions: { offerToReceiveAudio: false, offerToReceiveVideo: false } }
                : {};
        this.currentPeer = new window.SimplePeer({
            initiator: false,
            trickle: false,
            stream,
            ...otherOptions
        });

        // JUST ONCE?
        this.currentPeer.on('signal', (data) => {
            this.socket.emit('answercall', { signal: data, to: this.caller });
        });

        this.currentPeer.on('stream', (stream) => {
            debug.log('GOT PEER STREAM');
            if (cb) {
                cb(stream);
            }
        });

        this.currentPeer.signal(this.callerSignal!);
    };

    public leaveCall = () => {
        debug.log('LEAVING CALL AND SEND THIS TO', this.caller || this.callee || this.socketId);
        if (this.currentPeer) {
            debug.log('DESTROYING PEER!');
            this.currentPeer.destroy();
        }

        this.socket.removeListener('callaccepted', this.callAcceptedHandler);

        this.socket.emit('leavecall', { to: this.caller || this.callee || this.socketId });
        this.callerSignal = undefined;
        this.caller = undefined;
        this.callee = undefined;
        this.callerName = undefined;
    };

    private setUserInfos = () => {
        this.socket.emit('assign', { username: this.name, deviceId: this.deviceId, osInfo: this.osInfo });
    };
}
