import React from 'react';
import styled from 'styled-components';
import { useGlobal } from '../logic/main';
import { mobile, STYLE } from '../styles/variables';

const StyledWrapper = styled.div<{ isRoot?: boolean }>`
    width: 100vw;
    background-image: url(${({ isRoot }) => (isRoot ? '/wallpaper/wr.jpg' : '/wallpaper/w2.jpeg')});
    background-size: cover;
    height: 100vh;
    position: relative;

    @media ${mobile} {
        display: flex;
        flex-flow: column;
        padding: ${STYLE.default.padding};
        align-items: center;
        gap: ${STYLE.default.padding};
    }
`;

export const Background: React.FC = ({ children }) => {
    const [isRoot] = useGlobal((state) => state.isRoot);
    return <StyledWrapper isRoot={isRoot}>{children}</StyledWrapper>;
};
