import React from 'react';
import { Card, Title } from '../styles/styledComponents';
import { STYLE } from '../styles/variables';
import { useGlobal } from '../logic/main';
import styled from 'styled-components';
import { UserRow } from './UserRow';

const UsersWrapper = styled.div`
    margin-top: calc(2 * ${STYLE.default.padding});
    display: flex;
    flex-flow: column;
    gap: ${STYLE.default.padding};
`;

export const RootHome: React.FC = () => {
    const [{ onlineUsers, incomingStream, eventData, caller, isLoading, friendName }, { call, answer }] = useGlobal(
        (state) => ({
            onlineUsers: state.onlineUsers,
            incomingStream: state.incomingStream,
            isRoot: state.isRoot,
            caller: state.caller,
            isLoading: state.isLoading,
            friendName: state.friendName,
            eventData: state.eventData
        }),
        (actions) => ({
            call: actions.call,
            answer: actions.answer
        })
    );

    return (
        <Card style={{ top: STYLE.default.smallMargin, left: STYLE.default.smallMargin, padding: 0 }} visible={true}>
            <Title>{onlineUsers && onlineUsers.length > 0 ? 'Aktive Nutzer' : 'Keine aktiven Nutzer online'}</Title>
            <UsersWrapper>
                {onlineUsers?.map((user) => (
                    <UserRow
                        key={user.id}
                        name={user.name}
                        isLoading={isLoading}
                        osInfo={user.osInfo}
                        socketId={user.id}
                        isMyCall={caller == user.name || friendName == user.name}
                        isCalling={caller == user.name}
                        isStreaming={!!incomingStream || !!eventData}
                        onCallScreen={() => call('start', user.name, undefined, 'screen')}
                        onCallAudio={() => call('start', user.name, undefined, 'video', true)}
                        onCallVideo={() => call('start', user.name, undefined, 'video')}
                        onCallMotion={() => call('start', user.name, undefined, 'motion')}
                        onAccept={() => answer('start')}
                        onDecline={() => answer('stop')}
                    />
                ))}
            </UsersWrapper>
        </Card>
    );
};
