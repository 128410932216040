import styled from 'styled-components';
import { COLOR, mobile, STYLE } from './variables';
import { hexOpacity, invertHex } from '../utils/style';

export const Card = styled.section<{ visible?: boolean }>`
    color: ${COLOR.font};
    padding: ${STYLE.default.padding};
    background-color: ${hexOpacity(COLOR.cardBackground, 0.35)};
    box-shadow: 0 0 5px 10px ${hexOpacity(COLOR.cardBackground, 0.35)};
    display: flex;
    // backdrop-filter: blur(10px);
    flex-flow: column;
    position: fixed;
    max-width: 70vw;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity 0.5s ease-in-out;
    pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};

    @media ${mobile} {
        position: relative;
        top: unset !important;
        left: unset !important;
        right: unset !important;
        max-width: unset !important;
        width: 100%;
        transform: unset !important;
        display: ${({ visible }) => (visible ? 'flex' : 'none')};
    }
`;
export const Title = styled.span`
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.3;
    display: flex;
    flex-flow: row;
`;

export const H1 = styled.h1`
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.3;
    margin-top: 0;
`;

export const H2 = styled.h2`
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
    margin: 1rem 0;
`;

export const H3 = styled.h3`
    font-size: 1rem;
    font-weight: 600;
`;

export const H4 = styled.h4`
    font-size: 1rem;
    font-weight: 600;
`;

export const H5 = styled.h5`
    font-size: 1rem;
    font-weight: 600;
`;

export const P = styled.p<{ light?: boolean }>`
    opacity: ${({ light }) => (light ? 0.55 : 1)};
    margin-top: 1.5em;
    margin-bottom: 2em;
`;

export const A = styled.a<{ light?: boolean }>`
    color: ${({ light }) => (light ? invertHex(COLOR.contentFont) : COLOR.contentFont)};
    opacity: 1 !important;
    text-decoration: underline;
    cursor: pointer;
`;

export const Strong = styled.strong`
    font-weight: 600;
`;

export const Em = styled.em`
    font-style: italic;
`;
