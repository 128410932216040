const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

const norm = (x: number, y: number, z: number) =>
  Math.sqrt(x * x + y * y + z * z);

export class Filter {
  private ADAPTIVE_ACCEL_FILTER = false;
  private lastAccel: number[] = [0,0,0];
  private accelFilter: number[] = [0,0,0];

  constructor(adaptive?: boolean) {
    this.ADAPTIVE_ACCEL_FILTER = !!adaptive;
  }

  public filter(accelX: number, accelY: number, accelZ: number) {
    // high pass filter
    const updateFreq = 30; // match this to your update speed
    const cutOffFreq = 0.9;
    const RC = 1.0 / cutOffFreq;
    const dt = 1.0 / updateFreq;
    const filterConstant = RC / (dt + RC);
    let alpha = filterConstant;
    const kAccelerometerMinStep = 0.033;
    const kAccelerometerNoiseAttenuation = 3.0;

    if (this.ADAPTIVE_ACCEL_FILTER) {
      const d = clamp(
        Math.abs(
          norm(this.accelFilter[0], this.accelFilter[1], this.accelFilter[2]) -
            norm(accelX, accelY, accelZ)
        ) /
          kAccelerometerMinStep -
          1.0,
        0.0,
        1.0
      );
      alpha =
        (d * filterConstant) / kAccelerometerNoiseAttenuation +
        (1.0 - d) * filterConstant;
    }

    this.accelFilter[0] =
      alpha * (this.accelFilter[0] + accelX - this.lastAccel[0]);
    this.accelFilter[1] =
      alpha * (this.accelFilter[1] + accelY - this.lastAccel[1]);
    this.accelFilter[2] =
      alpha * (this.accelFilter[2] + accelZ - this.lastAccel[2]);

    this.lastAccel[0] = accelX;
    this.lastAccel[1] = accelY;
    this.lastAccel[2] = accelZ;
    return norm(this.accelFilter[0], this.accelFilter[1], this.accelFilter[2]);
  }
}

export const accFilter = new Filter(true);
