import { keyframes } from 'styled-components';

export const landscapeMobile = `only screen and (min-height: 319px) and (max-height: 480px) and (orientation: landscape)`;
export const big = `only screen and (min-width: 1536px)`;
export const mobile = `only screen and (max-width: 1020px)`;
export const smartphone = `only screen and (max-width: 768px)`;
export const firefox = '@-moz-document url-prefix()';
export const ie = 'all and (-ms-high-contrast: none), (-ms-high-contrast: active)';
export const safari = 'not all and (min-resolution:.001dpcm)';

const BR24COLORS = {
    white: '#ffffff',
    veryLightGrey: '#f4f4f4',
    lightGrey: '#d7d7d7',
    mediumGrey: '#8e949a',
    darkGrey: '#434343',
    veryDarkGrey: '#3c3c3c',
    black: '#323232',
    blueGrey: '#373B47',
    darkBlueGrey: '#292d3b',
    lightBlueGrey: '#484B5A',
    blue: '#0b9fd8',
    orange: '#e4743a',
    green: '#3ad29f',
    red: '#e64242',
    yellow: '#fbb800'
};

export const COLOR = {
    br24: BR24COLORS,
    cardBackground: BR24COLORS.darkBlueGrey,
    font: '#ffffff',
    pageBackground: BR24COLORS.veryLightGrey,
    contentBackground: BR24COLORS.white,
    contentFont: BR24COLORS.black,
    contentBox: BR24COLORS.veryLightGrey,
    error: BR24COLORS.red,
    warning: BR24COLORS.orange,
    info: BR24COLORS.green,
    overlay: BR24COLORS.darkBlueGrey,
    overlayGradient: `radial-gradient(${BR24COLORS.lightBlueGrey}, ${BR24COLORS.darkBlueGrey})`,
    overlayFont: BR24COLORS.white,
    primary: BR24COLORS.blue,
    secondary: BR24COLORS.darkBlueGrey,
    tertiary: BR24COLORS.mediumGrey
};

export const STYLE = {
    default: {
        padding: '30px',
        margin: '15vw',
        smallMargin: '40px',
        shadow: '0 0 7px rgba(0,0,0,0.5)',
        radius: '0.65rem',
        tabHeight: '50px',
        mapHeight: '30vh'
    },
    page: {
        maxWidth: '1536px'
    },
    header: {
        height: 'calc(40vh + 15vw)'
    },
    content: {
        maxWidth: '980px',
        width: '60vw',
        padding: {
            top: '2rem',
            right: '4rem',
            bottom: '0',
            left: '4rem'
        },
        margin: {
            top: '0',
            right: '5%',
            bottom: '0',
            left: '5%'
        }
    }
};

export const popOut = keyframes`
    0%{transform:scale(0)}
    80%{transform:scale(1.1)}
    100%{transform:scale(1)}
`;
export const fadeIn = keyframes`
    0%{opacity:0}
    100%{opacity:1}
`;

export const wiggle = keyframes`
  0% { transform: rotate(0deg) scale(1); }
  80% { transform: rotate(5deg) scale(1); }
  85% { transform: rotate(10deg) scale(1.1); }
  95% { transform: rotate(-10deg) scale(1.1); }
  100% { transform: rotate(0deg) scale(1); }
`;

export const pulse = keyframes`
    0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
`;

export const pulseImg = keyframes`
    0% {
		filter: drop-shadow(0 0 0px rgba(255, 255, 255, 0.25));
    }
	70% {
		filter: drop-shadow(10px 10px 100px rgba(255, 255, 255, 0));
	}
	100% {
		filter: drop-shadow(0 0 0px rgba(255, 255, 255, 0));
	}
`;
