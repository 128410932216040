import React from 'react';
import { Card, Title } from '../styles/styledComponents';
import { COLOR, STYLE } from '../styles/variables';
import { useGlobal } from '../logic/main';
import { BaseButton } from './Button';
import { Call as CallIcon, CallEnd } from '@mui/icons-material';

export const Call: React.FC = ({ children }) => {
    const [{ caller, state }, { answer }] = useGlobal(
        (state) => ({ username: state.username, caller: state.caller, state: state.currentState }),
        (actions) => ({ answer: actions.answer })
    );

    const handleDeclineButton = () => {
        answer('stop');
    };

    const handleCallButton = () => {
        answer('start');
    };

    return (
        <Card
            style={{
                bottom: STYLE.default.margin,
                left: STYLE.default.margin,
                position: 'fixed',
                flexFlow: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: STYLE.default.padding
            }}
            visible={state === 'call'}
        >
            <Title>{caller} ruft dich an!</Title>
            <BaseButton icon={<CallEnd />} bgColor={COLOR.error} onClick={handleDeclineButton} />
            <BaseButton icon={<CallIcon />} bgColor={COLOR.info} onClick={handleCallButton} animation={true} />
        </Card>
    );
};
