import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Card } from '../styles/styledComponents';
import { COLOR, STYLE } from '../styles/variables';
import { hexOpacity } from '../utils/style';

const StyledForm = styled.div`
    display: flex;
    flex-flow: column;
`;

const StyledInput = styled.input`
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: ${COLOR.font} 2px solid;
    color: ${COLOR.font};
    width: 6em;
    margin-top: 0;

    ::-webkit-input-placeholder {
        color: ${hexOpacity(COLOR.font, 0.65)};
    }

    ::-moz-placeholder {
        color: ${hexOpacity(COLOR.font, 0.65)};
    }

    ::-ms-placeholder {
        color: ${hexOpacity(COLOR.font, 0.65)};
    }

    ::placeholder {
        color: ${hexOpacity(COLOR.font, 0.65)};
    }
`;

const StyledLabel = styled.span`
    font-size: 0.75rem;
    color: ${COLOR.error};
    margin-top: calc(${STYLE.default.padding} / 3);
`;

interface Props {
    placeholder?: string;
    onInputChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
    errorText?: string;
    labelText?: string;
    width?: number;
    value?: string;
    disabled?: boolean;
    onBlur?: () => void;
}

export const Input: React.FC<Props> = ({
    placeholder,
    onInputChange,
    errorText,
    labelText,
    width,
    value = '',
    disabled,
    onBlur
}) => (
    <StyledForm>
        <StyledInput
            placeholder={placeholder}
            disabled={disabled}
            onChange={onInputChange}
            value={value}
            style={!!width ? { width: `${width}em` } : undefined}
            onBlur={onBlur}
        />
        <StyledLabel style={{ color: !!errorText ? COLOR.error : hexOpacity(COLOR.font, 0.75) }}>
            {errorText || labelText}
        </StyledLabel>
    </StyledForm>
);
