// https://capacitorjs.com/docs/v2/android/custom-code
import { Plugins } from '@capacitor/core';
export const { ContextReader, PersistentNotification } = Plugins;
import { ScreenRecorder } from 'capacitor-screen-recorder';

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const startScreenRecord =
    ScreenRecorder?.start ||
    function () {
        console.log('Nothing to start');
    };
export const stopScreenRecord =
    ScreenRecorder?.stop ||
    function () {
        console.warn('Nothing to stop');
    };
/*export const startScreenRecord = ContextReader?.startScreenRecord;
export const stopScreenRecord = ContextReader?.stopScreenRecorder;*/
export const takeScreenShot = ContextReader?.takeScreenshot;
export const isRooted = ContextReader?.isRooted;
export const startVideoRecord = ContextReader?.startVideoRecord;
export const stopVideoRecord = ContextReader?.stopVideoRecord;
