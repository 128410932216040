import React from 'react';
import styled from 'styled-components';
import { Call, CallEnd, Mic, ScreenShare, VideoCall, Vibration } from '@mui/icons-material';
import { COLOR, STYLE } from '../styles/variables';
import { BaseButton } from './Button';

const StyledWrapper = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    gap: ${STYLE.default.padding};
    box-shadow: ${STYLE.default.shadow} !important;
    border-radius: ${STYLE.default.radius} !important;
    padding: ${STYLE.default.padding} !important;
    max-width: 47vw;
`;

const Identifier = styled.div`
    display: flex;
    flex-flow: column;
    word-break: break-all;
`;

const Title = styled.div`
    font-size: 1.6rem;
`;

const SubTitle = styled.div`
    opacity: 0.5;
`;

const Buttons = styled.div`
    display: flex;
    flex-flow: row;
    gap: calc(${STYLE.default.padding} / 2);
    flex-wrap: wrap;
`;

interface Props {
    name: string;
    socketId: string;
    isCalling?: boolean;
    onAccept?: () => void;
    onCallScreen?: () => void;
    osInfo?: string;
    style?: React.CSSProperties;
    onCallVideo?: () => void;
    onDecline?: () => void;
    onCallMotion?: () => void;
    isLoading?: boolean;
    isStreaming?: boolean;
    onCallAudio?: () => void;
    isMyCall?: boolean;
}

export const UserRow: React.FC<Props> = ({
    socketId,
    osInfo,
    name,
    isCalling,
    style,
    onAccept,
    onCallMotion,
    onCallScreen,
    onCallVideo,
    onCallAudio,
    isStreaming,
    isLoading,
    onDecline,
    isMyCall
}) => (
    <StyledWrapper style={{ color: 'white', backgroundColor: COLOR.secondary, ...style }}>
        <Identifier>
            <Title>{name}</Title>
            <SubTitle>{osInfo}</SubTitle>
            <SubTitle>{socketId}</SubTitle>
        </Identifier>
        <Buttons>
            <BaseButton
                small={true}
                icon={<Vibration />}
                onClick={onCallMotion}
                disabled={isStreaming || isLoading || (!isMyCall && (isStreaming || isLoading))}
                bgColor={'violet'}
            />
            <BaseButton
                small={true}
                icon={<ScreenShare />}
                onClick={onCallScreen}
                disabled={isStreaming || isLoading || (!isMyCall && (isStreaming || isLoading))}
                bgColor={COLOR.primary}
            />
            <BaseButton
                small={true}
                icon={<VideoCall />}
                onClick={onCallVideo}
                disabled={isStreaming || isLoading || (!isMyCall && (isStreaming || isLoading))}
                bgColor={COLOR.warning}
            />
            <BaseButton
                small={true}
                icon={<Mic />}
                onClick={onCallAudio}
                disabled={isStreaming || isLoading || (!isMyCall && (isStreaming || isLoading))}
                bgColor={COLOR.br24.yellow}
            />
            <BaseButton
                small={true}
                icon={<CallEnd />}
                onClick={onDecline}
                disabled={(!isStreaming && !isLoading) || (!isMyCall && (isStreaming || isLoading))}
                bgColor={COLOR.error}
            />
            <BaseButton
                small={true}
                icon={<Call />}
                animation={isCalling}
                onClick={onAccept}
                disabled={isStreaming || !isCalling}
                bgColor={COLOR.info}
            />
        </Buttons>
    </StyledWrapper>
);
