import React from 'react';
import ReactDom from 'react-dom';
import { Background } from './components/Background';
import { GlobalStyle } from './styles/globalStyle';
import { Home } from './components/Home';
import { Meet } from './components/Meet';
import { Call } from './components/Call';
import { useGlobal } from './logic/main';
import { RootHome } from './components/Root.Home';
import { RootMeet } from './components/Root.Meet';
import { isMobile } from './mobile/ipc';
import { Listener } from './mobile/Listener';
import { HiddenSettings } from './components/Settings';

const App = () => {
    const [isRoot] = useGlobal((state) => state.isRoot);

    return (
        <>
            <GlobalStyle />
            <Background>
                {isRoot ? <RootHome /> : <Home />}
                {isRoot ? <RootMeet /> : <Meet />}
                {!isRoot && <Call />}
            </Background>
            {isMobile && <Listener />}
            <HiddenSettings />
        </>
    );
};
ReactDom.render(<App />, document.querySelector('main'));
